<template>
  <div class="delete_family_dialog">
    <div class="delete_family_dialog-box">
        <div class="delete_family_dialog-box-header">
            {{department.name}} -> {{sub}}
            <div @click="$emit('close')" class="exit-btn">
                <i class="material-icons">close</i>
            </div>
        </div>
        <div class="delete_family_dialog-box-content">
            <div class="delete_family_dialog-box-content-step-1">
                <div class="total-delete-msg">מחק לגמרי</div>
                <p class="total-delete-warning">
                    שים לב:
                    <br>
                    פעולה זו תמחק את התת מחלקה
                    <br>
                    מכלל הפריטים
                </p>
                <el-button v-if="!is_pending" @click="$emit('total_delete_family')" style="width:200px; margin-top:5px;" type="danger">אישור</el-button>
                <el-button v-else style="width:200px; margin-top:5px;" disabled type="danger">אנא המתן...</el-button>
            </div>
            <div class="delete_family_dialog-box-content-step-2">
                <div class="total-delete-msg">העבר למשפחה אחרת</div> 
                <el-select style="margin-top:5px; width:200px;" clearable  filterable  v-model="selected_new_sub" class="m-2" placeholder="בחר משפחה" size="small">
                    <el-option
                    v-for="_sub in department.subs"
                    :key="_sub"
                    :value="_sub"
                    :disabled="_sub==sub"
                    />
                </el-select>
                <el-button v-if="!is_pending" @click="handle_change_family" style="width:200px; margin-top:5px;" type="danger">אישור</el-button>
                <el-button v-else  style="width:200px; margin-top:5px;" type="danger" disabled>אנא המתן...</el-button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {slide_pop_error} from '../../../../Methods/Msgs'
export default {
    props:['department','sub'],
    emits:['close','change_family','total_delete_family'],
    setup(_,{emit}){

        const is_pending = ref(false)
        const selected_new_sub = ref('')

        

        const handle_change_family = () => {
            if(!selected_new_sub.value){
                slide_pop_error('עליך לבחור משפחה!')
            }else{
                is_pending.value = true
                emit('change_family',selected_new_sub.value)
            }
        }

        return{
            handle_change_family,
            selected_new_sub,
            is_pending,
        }
    }
}
</script>

<style scoped>
    .delete_family_dialog{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
    }
    .delete_family_dialog-box{
        width: 100%;
        max-width: 500px;
        height: 400px;
        background: #fff;
        border-radius: 10px;
    }
    .delete_family_dialog-box-header{
        position: relative;
        width: 100%;
        height: 50px;
        border-radius: 10px 10px 0 0;
        background: var(--danger);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
    }
    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        background: #fff;
        border-radius: 50%;
        color: var(--danger);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .delete_family_dialog-box-content{
        width: 100%;
        height: calc(100% - 50px);
    }
    .delete_family_dialog-box-content-step-1,.delete_family_dialog-box-content-step-2{
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .total-delete-msg{
        width: 400px;
        height: 30px;
        /* border-radius: 10px; */
        background: var(--danger);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
    }
    .total-delete-warning{
        color: var(--danger);
        font-weight: 500;
        text-align: center;
    }
    
</style>